var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useContext, useEffect, useReducer } from "react";
import { AppContext } from "src/contexts/AppContext";
import translate from "src/i18n/translate";
import { ResponseRateSection } from "./ces/ResponseRateSection";
import { openFlyout } from "../../../static/js/commons";
import { ResponseRateFlyout } from "./ces/ResponseRateFlyout";
import { sendRequest } from "src/common/http";
import WidgetLoader from "src/features/common/elements/WidgetLoader";
import ApiError from "src/features/common/elements/ApiError";
var defaultAction = {
    type: "",
    responseRateOverview: null,
    isDrilldownOpen: false,
    CESHomePath: ""
};
var initialState = {
    responseRateOverview: null,
    responseRateOverviewError: false,
    isDrilldownOpen: false,
    showLoader: true,
    CESHomePath: ""
};
var stateReducer = function (state, action) {
    switch (action.type) {
        case "setResponseRateOverview":
            return __assign(__assign({}, state), { responseRateOverview: action.responseRateOverview, showLoader: false });
        case "setResponseRateOverviewError":
            return __assign(__assign({}, state), { responseRateOverviewError: true, showLoader: false });
        case "setCourseEvaluationsHomePath":
            return __assign(__assign({}, state), { CESHomePath: action.CESHomePath });
        case "ToggleDrilldown":
            return __assign(__assign({}, state), { isDrilldownOpen: !state.isDrilldownOpen });
        default:
            return state;
    }
};
export function ResponseRateOverview() {
    var _a = useReducer(stateReducer, initialState), state = _a[0], dispatch = _a[1];
    var sysUser = useContext(AppContext).sysUser;
    var setIsDrilldownOpen = function () {
        dispatch(__assign(__assign({}, defaultAction), { type: "ToggleDrilldown" }));
    };
    var openDrilldownView = function () {
        setTimeout(function () {
            return openFlyout("drill-down-view", "course-charts", "response-rate-close-btn");
        }, 400);
        setIsDrilldownOpen();
    };
    useEffect(function () {
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setResponseRateOverview", responseRateOverview: result }));
        };
        var onError = function () {
            dispatch(__assign(__assign({}, defaultAction), { type: "setResponseRateOverviewError" }));
        };
        sendRequest({ uri: "/ces/ce-response-rates", camelize: true }, applyResult, onError);
        var applyPathResult = function (result) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setCourseEvaluationsHomePath", CESHomePath: result }));
        };
        sendRequest({
            uri: "/ces/results-home-path",
            camelize: true
        }, applyPathResult);
    }, []);
    return (_jsx(_Fragment, { children: state.responseRateOverview && state.responseRateOverview.courseEvaluations >= 0 && (_jsxs("div", { className: "wm-grid", children: [_jsx("div", { className: "wm-grid-row", children: _jsx("div", { className: "grid-container wm-grid-col-6 wm-grid-col-md-10 mb-0", children: _jsxs("div", { className: (sysUser === null || sysUser === void 0 ? void 0 : sysUser.roles.includes("administrator"))
                                ? "widget-card respone-rate-overview-card"
                                : "widget-card respone-rate-overview-card my-tasks-margin", children: [_jsxs("div", { className: "card-header", children: [_jsx("div", { className: "response-rate-heading", children: translate("app.home.responseRateOverview.title") }), _jsx("div", { className: "base-font", children: translate("app.home.responseRateOverview.desc") })] }), state.showLoader && (_jsx(WidgetLoader, { loaderContainerClasses: "loader", loaderClasses: "content" })), state.responseRateOverviewError && (_jsx("div", { className: "widget-body", children: _jsx(ApiError, {}) })), !state.showLoader && !state.responseRateOverviewError && (_jsx(ResponseRateOverviewBody, { responseRateOverview: state.responseRateOverview, openDrilldownView: openDrilldownView, cesHomePath: state.CESHomePath }))] }) }) }), state.isDrilldownOpen && state.responseRateOverview && (_jsxs(Fragment, { children: [_jsx("div", { className: "sr-only", id: "course-dialog-wrapper-flyout-top", tabIndex: 0 }), _jsx(ResponseRateFlyout, { setIsDrilldownOpen: setIsDrilldownOpen, responseRateByCourseSection: state.responseRateOverview.responseRateByCourseSection }), _jsx("div", { className: "sr-only", id: "course-dialog-wrapper-flyout-top", tabIndex: 0 })] }))] })) }));
}
function ResponseRateOverviewBody(_a) {
    var responseRateOverview = _a.responseRateOverview, openDrilldownView = _a.openDrilldownView, cesHomePath = _a.cesHomePath;
    return (_jsxs("div", { className: "widget-body", children: [_jsx("div", { className: "overview", children: translate("app.home.responseRateOverview.overview") }), _jsx("div", { className: "responses", children: responseRateOverview === null || responseRateOverview === void 0 ? void 0 : responseRateOverview.courseEvaluations }), _jsx("div", { className: "course-evaluations-desc", children: translate("app.home.responseRateOverview.courseEvaluationsDesc") }), responseRateOverview && responseRateOverview.courseEvaluations > 0 && (_jsx(ResponseRateSection, { responseRateOverview: responseRateOverview, openDrilldownView: openDrilldownView })), cesHomePath !== "" && (_jsx("div", { className: "visitCesHome", children: _jsx("a", { className: "navigation-link", href: cesHomePath, children: translate("app.ces.responseRate.cesResultsHome") }) }))] }));
}
