import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmButton, WmProgressIndicator, WmProgressMonitor, WmProgressSlice } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
import translate from "src/i18n/translate";
export var optedOut = function (optedOut) {
    return useIntl().formatMessage({
        id: "app.home.responseRateOverview.optedOut"
    }, { number: optedOut });
};
export var groupLegend = function () {
    return useIntl().formatMessage({
        id: "app.home.responseRateOverview.groupLegend"
    });
};
export var respondedText = function () {
    return useIntl().formatMessage({
        id: "app.ces.responseRate.responded"
    });
};
export var tooltipRespondedText = function (students) {
    return useIntl().formatMessage({ id: "app.home.responseRateOverview.tooltipRespondedText" }, { students: students });
};
export var tooltipNotRespondedText = function () {
    return useIntl().formatMessage({
        id: "app.home.responseRateOverview.tooltipNotRespondedText"
    });
};
export var notRespondedText = function () {
    return useIntl().formatMessage({
        id: "app.home.responseRateOverview.notResponded"
    });
};
export var chartLabel = function () {
    return useIntl().formatMessage({
        id: "app.home.responseRateOverview.notResponded"
    });
};
export function ResponseRateSection(_a) {
    var responseRateOverview = _a.responseRateOverview, openDrilldownView = _a.openDrilldownView;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "response-rates", children: translate("app.home.responseRateOverview.responseRates") }), _jsx("div", { className: "response-rate-chart", children: _jsx(WmProgressMonitor, { id: "ces-response-rate", groupLegend: groupLegend(), breakpoint: "10px", barLabelsWidth: "0", children: _jsxs(WmProgressIndicator, { label: " ", children: [_jsx(WmProgressSlice, { amount: responseRateOverview.respondedPercentage.toString(), popoverText: tooltipRespondedText(responseRateOverview.optedOut), legend: respondedText(), popoverTitle: "".concat(responseRateOverview.responded, "/").concat(responseRateOverview.total), title: respondedText() }), _jsx(WmProgressSlice, { amount: responseRateOverview.notRespondedPercentage.toString(), popoverText: tooltipNotRespondedText(), legend: notRespondedText(), popoverTitle: "".concat(responseRateOverview.notResponded, "/").concat(responseRateOverview.total), title: notRespondedText() })] }) }) }), _jsx("div", { className: "opted-out", children: optedOut(responseRateOverview.optedOut) }), _jsx("div", { className: "view-by-course", children: _jsx(WmButton, { "button-type": "textonly", icon: "f208", onClick: function () { return openDrilldownView(); }, children: translate("app.home.responseRateOverview.viewCourseBySection") }) })] }));
}
