import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmProgressIndicator, WmProgressMonitor, WmProgressSlice } from "@watermarkinsights/ripple-react";
import { groupLegend, notRespondedText, optedOut, respondedText, tooltipNotRespondedText, tooltipRespondedText } from "./ResponseRateSection";
import useSmallScreenView from "src/features/common/useSmallScreenView";
export function ResponseRateFlyoutCharts(_a) {
    var responseRate = _a.responseRate, index = _a.index, courseSectionLength = _a.courseSectionLength;
    var isSmallScreen = useSmallScreenView(1024);
    var lastRowLength = isSmallScreen
        ? courseSectionLength % 2 || 2
        : courseSectionLength % 3 || 3;
    var isLastRow = index >= courseSectionLength - lastRowLength;
    return (_jsxs("div", { className: isLastRow
            ? "response-rate-chart-container last-row"
            : "response-rate-chart-container", children: [_jsx("div", { className: "course-section", children: responseRate.courseSection }), _jsx("div", { className: "project-name", children: responseRate.projectTitle }), _jsxs("div", { className: "course-section-chart", children: [_jsx(WmProgressMonitor, { id: "ces-response-rate", groupLegend: groupLegend(), barLabelsWidth: "0", children: _jsxs(WmProgressIndicator, { label: " ", children: [_jsx(WmProgressSlice, { amount: responseRate.respondedPercentage.toString(), popoverText: tooltipRespondedText(responseRate.optedOut), legend: respondedText(), popoverTitle: "".concat(responseRate.responded, "/").concat(responseRate.total), title: respondedText() }), _jsx(WmProgressSlice, { amount: responseRate.notRespondedPercentage.toString(), popoverText: tooltipNotRespondedText(), legend: notRespondedText(), popoverTitle: "".concat(responseRate.notResponded, "/").concat(responseRate.total), title: notRespondedText() })] }) }), _jsx("div", { className: "opted-out", children: optedOut(responseRate.optedOut) })] })] }));
}
