var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmButton, WmOption, WmSelect } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
import translate from "src/i18n/translate";
import { closeFlyout } from "../../../../static/js/commons";
import { ResponseRateFlyoutCharts } from "./ResponseFlyoutCharts";
import { useEffect, useReducer } from "react";
var SortBy;
(function (SortBy) {
    SortBy["ResponseRateDesc"] = "response-rate-desc";
    SortBy["ResponseRateAsc"] = "response-rate-asc";
    SortBy["CourseSectionAsc"] = "course-section-asc";
    SortBy["CourseSectionDesc"] = "course-section-desc";
})(SortBy || (SortBy = {}));
var defaultAction = {
    type: "",
    sortBy: "",
    sortedResponseRateByCourseSection: []
};
var initialState = {
    sortBy: SortBy.ResponseRateDesc,
    sortedResponseRateByCourseSection: []
};
var stateReducer = function (state, action) {
    switch (action.type) {
        case "setSortBy":
            return __assign(__assign({}, state), { sortBy: action.sortBy });
        case "setSortedResponseRateByCourseSection":
            return __assign(__assign({}, state), { sortedResponseRateByCourseSection: action.sortedResponseRateByCourseSection });
        default:
            return state;
    }
};
function sortCourseSections(courseSection1, courseSection2, sortBy) {
    switch (sortBy) {
        case SortBy.ResponseRateDesc:
            return (courseSection2.respondedPercentage - courseSection1.respondedPercentage);
        case SortBy.ResponseRateAsc:
            return (courseSection1.respondedPercentage - courseSection2.respondedPercentage);
        case SortBy.CourseSectionAsc:
        case SortBy.CourseSectionDesc:
            var title1 = courseSection1.courseSection.toLowerCase();
            var title2 = courseSection2.courseSection.toLowerCase();
            if (title1 > title2) {
                return sortBy === SortBy.CourseSectionAsc ? 1 : -1;
            }
            if (title1 < title2) {
                return sortBy === SortBy.CourseSectionAsc ? -1 : 1;
            }
            return 0;
        default:
            return 0;
    }
}
export function ResponseRateFlyout(_a) {
    var setIsDrilldownOpen = _a.setIsDrilldownOpen, responseRateByCourseSection = _a.responseRateByCourseSection;
    var closeDrillDownView = function () {
        setIsDrilldownOpen();
        closeFlyout();
    };
    var _b = useReducer(stateReducer, initialState), state = _b[0], dispatch = _b[1];
    var closeTooltip = useIntl().formatMessage({
        id: "app.ces.responseRate.drilldownTooltipClose"
    });
    var responseRateLabel = useIntl().formatMessage({
        id: "app.home.responseRateOverview.flyout.responseRateLabel"
    });
    function updateSortByValue(sortByValue) {
        dispatch(__assign(__assign({}, defaultAction), { type: "setSortBy", sortBy: sortByValue }));
    }
    useEffect(function () {
        var sorted = responseRateByCourseSection.sort(function (courseSection1, courseSection2) {
            return sortCourseSections(courseSection1, courseSection2, state.sortBy);
        });
        dispatch(__assign(__assign({}, defaultAction), { type: "setSortedResponseRateByCourseSection", sortedResponseRateByCourseSection: sorted }));
    }, [state.sortBy]);
    return (_jsxs("div", { className: "flyout-panel half-flyout show", role: "dialog", id: "drill-down-view", tabIndex: -1, "aria-label": responseRateLabel, children: [_jsx("div", { className: "overlay" }), _jsxs("div", { className: "container-box response-rate-flyout-container", children: [_jsx("div", { className: "paper-card", children: _jsxs("div", { className: "overlay-content", children: [_jsx("div", { className: "helper-text", children: _jsx("div", { className: "description", id: "all-programs-description", children: translate("app.home.responseRateOverview.flyoutHeading") }) }), _jsx(WmButton, { buttonType: "navigational", icon: "f156", tooltip: closeTooltip, tooltipPosition: "bottom", id: "response-rate-close-btn", class: "hydrated", iconSize: "18px", onClick: closeDrillDownView })] }) }), _jsxs("section", { id: "org-table-catalog", className: "flyout-body", children: [_jsx(SortSelectMenu, { updateSortByValue: updateSortByValue, sortBy: state.sortBy }), _jsx("div", { className: "course-section-charts", children: state.sortedResponseRateByCourseSection.map(function (responseRate, index) {
                                    return (_jsx(ResponseRateFlyoutCharts, { responseRate: responseRate, index: index, courseSectionLength: state.sortedResponseRateByCourseSection.length }, "".concat(responseRate.id)));
                                }) })] }), _jsx("div", { className: "ces-flyout-border" }), _jsx("div", { className: "close-button-container", children: _jsx(WmButton, { class: "close-ces-flyout-task", buttonType: "primary", onClick: closeDrillDownView, children: translate("app.ces.responseRate.drilldownTooltipClose") }) })] })] }));
}
var selectOptions = [
    {
        id: SortBy.ResponseRateDesc,
        value: translate("app.home.responseRateOverview.flyout.highToLow")
    },
    {
        id: SortBy.ResponseRateAsc,
        value: translate("app.home.responseRateOverview.flyout.lowToHigh")
    },
    {
        id: SortBy.CourseSectionAsc,
        value: translate("app.home.responseRateOverview.flyout.courseSectionAZ")
    },
    {
        id: SortBy.CourseSectionDesc,
        value: translate("app.home.responseRateOverview.flyout.courseSectionZA")
    }
];
function SortSelectMenu(_a) {
    var updateSortByValue = _a.updateSortByValue, sortBy = _a.sortBy;
    return (_jsx("div", { className: "sort-by-select", children: _jsx(WmSelect, { label: "Sort by", labelPosition: "left", children: selectOptions.map(function (option, index) {
                return (_jsx(WmOption, { value: option.id, selected: sortBy === option.id, onClick: function () { return updateSortByValue(option.id); }, children: option.value }, option.id));
            }) }) }));
}
